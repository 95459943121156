<template>
  <div class="tabs-style">
    <account-role-title role="Store" />
    <!-- <breadcrumbs /> -->
    <span class="title">{{ $t("Refunds") }}</span>
    <v-tabs v-model="tab" show-arrows class="deals-tabs">
      <v-tab
          v-for="(tab, index) in tabs"
          :key="index"
          @click="$router.push({name: $route.name.includes('admin-refunds') ? 'admin-refunds-store-list' : 'seller-refunds-list', params: $route.name.includes('admin-refunds') ? {store_id: storeId, tab: tab.route} : {tab: tab.route}, query:{t:new Date().getTime()}})"
      >
        <span>{{ $t(tab.title) }}</span>
      </v-tab>
      <!-- tabs item -->
      <v-tabs-items v-model="tab">
        <v-tab-item v-for="(tab, index) in tabs" :key="index">
          <!-- tab component -->
          <orders-table v-if="tab.route == $route.params.tab" :url="url" order-link="seller-order" :storeId="storeId"></orders-table>
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>
  </div>
</template>

<script>
import {ref, watch, computed} from "@vue/composition-api/dist/vue-composition-api";
import {useRouter} from "@core/utils";
import ordersTable from "@/views/cabinet/order/order-list/OrdersTable";

export default {
  name: "SellerRefundList",
  components:{ordersTable},
  setup() {
    const { route, router } = useRouter()
    const tabs = [
      { title: 'All', route: 'all' },
      { title: 'Refund Requests', route: 'refund-requests' },
      { title: 'Refunded', route: 'refunded' },
    ]
    const tab = ref(tabs.findIndex(tab => tab.route === route.value.params.tab))
    watch(() => route.value.params.tab, (newTab) => tab.value = tabs.findIndex(tab => tab.route === newTab))
    return {
      storeId: computed(() => route.value.params.store_id),
      url: computed(() => route.value.name === 'admin-refunds-store-list' ? 'admin/refunds' : 'seller-refunds-list'),
      tab,
      tabs,
    }
  }
}
</script>
